<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>화상 수업시간 선택</h2>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                  <!-- 시리즈 학습일정 -->
                  <div style="width:100%;">
                    <CalendarMonth :legends="legends" :events="events" @updateLegend="updateLegend"></CalendarMonth>
                    <!-- 화상수업 시간 선택 -->
                    <div class="program_time_choice_wrap">
                      <div class="program_time_choice_title">
                        화상 수업시간 선택<span v-text="` : ${remainingClass}회를 선택해 주세요`" v-if="remainingClass > 0"></span>
                      </div>
                      <div class="program_time_day_box day_title">
                        <h4>요일</h4>
                        <ul class="program_time_day_list">
                          <li @click="selectPattern('monday')" :class="`${formData.pattern.includes('monday') ? 'day_display_on01' : ''}`">월</li>
                          <li @click="selectPattern('tuesday')" :class="`${formData.pattern.includes('tuesday') ? 'day_display_on01' : ''}`">화</li>
                          <li @click="selectPattern('wednesday')" :class="`${formData.pattern.includes('wednesday') ? 'day_display_on01' : ''}`">수</li>
                          <li @click="selectPattern('thursday')" :class="`${formData.pattern.includes('thursday') ? 'day_display_on01' : ''}`">목</li>
                          <li @click="selectPattern('friday')" :class="`${formData.pattern.includes('friday') ? 'day_display_on01' : ''}`">금</li>
                        </ul>
                      </div>
                      <div class="program_time_day_box time_h4_title" style="margin-top:20px;" v-if="display.timeSelection">
                        <h4>시간</h4>
                        <ul class="program_time_day_list02">
                          <li @click="selectTime('1600')" :class="`${formData.time === '1600' ? 'day_display_on02' : ''}`">16 : 00</li>
                          <li @click="selectTime('1630')" :class="`${formData.time === '1630' ? 'day_display_on02' : ''}`">16 : 30</li>
                          <li @click="selectTime('1700')" :class="`${formData.time === '1700' ? 'day_display_on02' : ''}`">17 : 00</li>
                          <li @click="selectTime('1730')" :class="`${formData.time === '1730' ? 'day_display_on02' : ''}`">17 : 30</li>
                          <li @click="selectTime('1800')" :class="`${formData.time === '1800' ? 'day_display_on02' : ''}`">18 : 00</li>
                          <li @click="selectTime('1830')" :class="`${formData.time === '1830' ? 'day_display_on02' : ''}`">18 : 30</li>
                          <li @click="selectTime('1900')" :class="`${formData.time === '1900' ? 'day_display_on02' : ''}`">19 : 00</li>
                          <li @click="selectTime('1930')" :class="`${formData.time === '1930' ? 'day_display_on02' : ''}`">19 : 30</li>
                          <li @click="selectTime('2000')" :class="`${formData.time === '2000' ? 'day_display_on02' : ''}`">20 : 00</li>
                          <li @click="selectTime('2030')" :class="`${formData.time === '2030' ? 'day_display_on02' : ''}`">20 : 30</li>
                          <li @click="selectTime('2100')" :class="`${formData.time === '2100' ? 'day_display_on02' : ''}`">21 : 00</li>
                          <li @click="selectTime('2130')" :class="`${formData.time === '2130' ? 'day_display_on02' : ''}`">21 : 30</li>
                          <li @click="selectTime('2200')" :class="`${formData.time === '2200' ? 'day_display_on02' : ''}`">22 : 00</li>
                          <li @click="selectTime('2230')" :class="`${formData.time === '2230' ? 'day_display_on02' : ''}`">22 : 30</li>
                          <li @click="selectTime('2300')" :class="`${formData.time === '2300' ? 'day_display_on02' : ''}`">23 : 00</li>
                          <li @click="selectTime('2330')" :class="`${formData.time === '2330' ? 'day_display_on02' : ''}`">23 : 30</li>
                        </ul>
                      </div>
                      <div class="program_tutor_box tutor_h4_title" v-if="display.tutorSelection">
                        <div class="get-tutor-loading loading loading--box" v-if="getTutorloading"></div>
                        <h4>Tutor</h4>
                        <div class="program_tutor_list">
                          <b-form-select v-model="formData.tutor" :options="tutorOptions"></b-form-select>
                        </div>
                      </div>
                    </div>
                    <!-- //화상수업 시간 선택 -->
                  </div>
                  <!--// 시리즈 학습일정 -->
                  <!-- 충전금액 -->
                  <div class="program_time_btn_wrap" v-if="display.paymentPage">
                    <a href="#" @click.prevent="enroll">
                      <div class="program_time_bottom_btn">
                        <p class="program_time_bottom_btn_title">
                          <span class="program_time_bottom_btn_title01">선택완료</span><br />
                          <span class="program_time_bottom_btn_title02">결재페이지 이동</span>
                        </p>
                        <div class="record_btn_click_area">
                          <img src="/image/sub/study_record_btn04.png" alt="클릭 아이콘">
                        </div>
                      </div>
                    </a>
                  </div>
                  <!-- //충전금액 -->
                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'program.level.class-per-week',params: { level: this.level } }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import Schedule from '@/models/Schedule'
import StudyRoom from '@/models/StudyRoom'
import CalendarMonth from '@/components/Calendar/CalendarMonth'
import User from '@/models/User'
import ContentFooter from '@/layouts/components/ContentFooter'
import {
  BFormSelect
} from 'bootstrap-vue'

export default {
  name: 'Program.SetVideoClass',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    CalendarMonth,
    BFormSelect,
    ContentFooter
  },
  data () {
    return {
      level: typeof this.$route.params.levelId !== 'undefined' ? this.$route.params.levelId : '',
      classPerWeek: typeof this.$route.params.classPerWeekId !== 'undefined' ? this.$route.params.classPerWeekId : '',
      productId: typeof this.$route.query.productId !== 'undefined' ? this.$route.query.productId : '',
      oneOnOneClass: typeof this.$route.query.oneOnOneClass !== 'undefined' ? this.$route.query.oneOnOneClass : '',
      loading: false,
      getTutorloading: false,
      formData: {
        pattern: [],
        time: '',
        tutor: null
      },
      display: {
        timeSelection: false,
        tutorSelection: false,
        paymentPage: false
      },
      weeks: {},
      tutorOptions: [],
      events: {},
      legends: [
        'Scheduled',
        'Attend',
        'Absent',
        'Teacher Cancel',
        'Postponed'
      ]
    }
  },
  computed: {
    remainingClass () {
      return this.classPerWeek - this.formData.pattern.length
    }
  },
  watch: {
    'formData.time': function (val) {
      if (val !== '') {
        this.display.tutorSelection = true
      } else {
        this.display.tutorSelection = false
        this.formData.tutor = null
      }
    },
    'formData.tutor': function (val) {
      if (val !== null) {
        this.display.paymentPage = true
      } else {
        this.display.paymentPage = false
      }
    }
  },
  mounted () {
    this.getUserSchedule()
  },
  methods: {
    async getUserSchedule () {
      this.loading = true
      const response = await User.getSchedule({ legends: this.legends })
      this.events = response.data.events
      this.loading = false
    },
    selectPattern (day) {
      const isSelectedPatternExist = this.formData.pattern.indexOf(day)

      if (isSelectedPatternExist >= 0) {
        this.formData.pattern.splice(isSelectedPatternExist, 1)
      } else {
        if (this.formData.pattern.length < this.classPerWeek) {
          this.formData.pattern.push(day)
        }
      }

      if (this.formData.pattern.length >= this.classPerWeek) {
        this.display.timeSelection = true
      } else {
        this.display.timeSelection = false
        this.formData.time = ''
      }
    },
    async selectTime (time) {
      try {
        if (time !== this.formData.time) {
          this.formData.tutor = null
        }
        this.formData.time = time
        this.getTutorloading = true
        const response = await Schedule.getTutors({ ...this.formData, level: this.level, oneOnOneClass: this.oneOnOneClass })
        this.tutorOptions = response.data
        this.getTutorloading = false
      } catch (e) {
        // Do Nothing Here ...
      }
    },
    async enroll () {
      try {
        const response = await StudyRoom.enroll({ ...this.formData, level: this.level, classPerWeek: this.classPerWeek, productId: this.productId, oneOnOneClass: this.oneOnOneClass })
        this.$swal({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        if (response.data.userData !== undefined) {
          localStorage.setItem('userData', JSON.stringify(response.data.userData))
          this.$store.dispatch('SET_USER_DATA', response.data.userData)
          this.$router.replace({ name: 'study-room' })
        }
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data ?? e.message,
          icon: 'error'
        })
      }
    },
    async updateLegend (data) {
      var index = this.legends.indexOf(data)
      if (index !== -1) {
        this.legends.splice(index, 1)
      } else {
        this.legends.push(data)
      }
      this.getUserSchedule()
    }
  }
}
</script>

<style scoped>
.day_title {
  padding-top: 13px;
}
.get-tutor-loading {
  left:0px;
  top:0px;
}
.program_tutor_box {
  width: 99%;
  border-radius: 25px;
  background: #fff;
  box-shadow: 1px 1px 5px 1px rgb(166 118 85 / 30%);
  overflow: hidden;
  padding: 20px 20px 20px 20px;
  margin: 20px 0px;
}
.tutor_h4_title h4 {
  font-size: 16px;
  color: #754405;
  font-weight: 500;
  float: left;
  width: 55px;
  text-align: right;
}
.tutor_h4_title {
  position: relative;
}
.program_tutor_list {
  width: 80%;
  float: left;
  margin-left: 20px;
}
</style>
